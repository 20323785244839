<template>
  <g>
    <g
      style="fill:#000000; fill-opacity:1;stroke:#000000; stroke-opacity:1;
stroke-linecap:round; stroke-linejoin:round;"
      transform="translate(0 0) scale(1 1)"
    ></g>
    <g
      style="fill:#000000; fill-opacity:0.0; 
stroke:#000000; stroke-width:-10; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:-10; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <rect x="69690" y="65360" width="42520" height="12600" rx="0" />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <rect x="3940" y="3940" width="109050" height="74800" rx="0" />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <rect x="4720" y="4720" width="107490" height="73240" rx="0" />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <path
        d="M23620 4720
L23620 3940
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <path
        d="M43310 4720
L43310 3940
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <path
        d="M62990 4720
L62990 3940
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <path
        d="M82680 4720
L82680 3940
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <path
        d="M102360 4720
L102360 3940
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <text
        x="13780"
        y="4580"
        textLength="550"
        font-size="680"
        lengthAdjust="spacingAndGlyphs"
        text-anchor="start"
        opacity="0"
      >
        1
      </text>
      <path
        d="M14190 4560
L13900 4560
"
      />
      <path
        d="M14050 4560
L14050 4050
L14000 4120
L13950 4170
L13900 4190
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <text
        x="33460"
        y="4580"
        textLength="550"
        font-size="680"
        lengthAdjust="spacingAndGlyphs"
        text-anchor="start"
        opacity="0"
      >
        2
      </text>
      <path
        d="M33580 4090
L33610 4070
L33660 4050
L33780 4050
L33830 4070
L33850 4090
L33870 4140
L33870 4190
L33850 4260
L33560 4560
L33870 4560
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <text
        x="53150"
        y="4580"
        textLength="550"
        font-size="680"
        lengthAdjust="spacingAndGlyphs"
        text-anchor="start"
        opacity="0"
      >
        3
      </text>
      <path
        d="M53250 4050
L53560 4050
L53390 4240
L53470 4240
L53520 4260
L53540 4290
L53560 4340
L53560 4460
L53540 4510
L53520 4530
L53470 4560
L53320 4560
L53270 4530
L53250 4510
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <text
        x="72830"
        y="4580"
        textLength="550"
        font-size="680"
        lengthAdjust="spacingAndGlyphs"
        text-anchor="start"
        opacity="0"
      >
        4
      </text>
      <path
        d="M73200 4220
L73200 4560
"
      />
      <path
        d="M73070 4020
L72950 4390
L73270 4390
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <text
        x="92520"
        y="4580"
        textLength="550"
        font-size="680"
        lengthAdjust="spacingAndGlyphs"
        text-anchor="start"
        opacity="0"
      >
        5
      </text>
      <path
        d="M92910 4050
L92670 4050
L92640 4290
L92670 4260
L92720 4240
L92840 4240
L92890 4260
L92910 4290
L92930 4340
L92930 4460
L92910 4510
L92890 4530
L92840 4560
L92720 4560
L92670 4530
L92640 4510
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <text
        x="112200"
        y="4580"
        textLength="550"
        font-size="680"
        lengthAdjust="spacingAndGlyphs"
        text-anchor="start"
        opacity="0"
      >
        6
      </text>
      <path
        d="M112570 4050
L112470 4050
L112420 4070
L112400 4090
L112350 4170
L112320 4260
L112320 4460
L112350 4510
L112370 4530
L112420 4560
L112520 4560
L112570 4530
L112590 4510
L112610 4460
L112610 4340
L112590 4290
L112570 4260
L112520 4240
L112420 4240
L112370 4260
L112350 4290
L112320 4340
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <path
        d="M23620 77960
L23620 78740
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <path
        d="M43310 77960
L43310 78740
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <path
        d="M62990 77960
L62990 78740
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <path
        d="M82680 77960
L82680 78740
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <path
        d="M102360 77960
L102360 78740
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <text
        x="13780"
        y="78600"
        textLength="550"
        font-size="680"
        lengthAdjust="spacingAndGlyphs"
        text-anchor="start"
        opacity="0"
      >
        1
      </text>
      <path
        d="M14190 78580
L13900 78580
"
      />
      <path
        d="M14050 78580
L14050 78070
L14000 78140
L13950 78190
L13900 78210
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <text
        x="33460"
        y="78600"
        textLength="550"
        font-size="680"
        lengthAdjust="spacingAndGlyphs"
        text-anchor="start"
        opacity="0"
      >
        2
      </text>
      <path
        d="M33580 78110
L33610 78090
L33660 78070
L33780 78070
L33830 78090
L33850 78110
L33870 78160
L33870 78210
L33850 78280
L33560 78580
L33870 78580
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <text
        x="53150"
        y="78600"
        textLength="550"
        font-size="680"
        lengthAdjust="spacingAndGlyphs"
        text-anchor="start"
        opacity="0"
      >
        3
      </text>
      <path
        d="M53250 78070
L53560 78070
L53390 78260
L53470 78260
L53520 78280
L53540 78310
L53560 78360
L53560 78480
L53540 78530
L53520 78550
L53470 78580
L53320 78580
L53270 78550
L53250 78530
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <text
        x="72830"
        y="78600"
        textLength="550"
        font-size="680"
        lengthAdjust="spacingAndGlyphs"
        text-anchor="start"
        opacity="0"
      >
        4
      </text>
      <path
        d="M73200 78240
L73200 78580
"
      />
      <path
        d="M73070 78040
L72950 78410
L73270 78410
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <text
        x="92520"
        y="78600"
        textLength="550"
        font-size="680"
        lengthAdjust="spacingAndGlyphs"
        text-anchor="start"
        opacity="0"
      >
        5
      </text>
      <path
        d="M92910 78070
L92670 78070
L92640 78310
L92670 78280
L92720 78260
L92840 78260
L92890 78280
L92910 78310
L92930 78360
L92930 78480
L92910 78530
L92890 78550
L92840 78580
L92720 78580
L92670 78550
L92640 78530
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <text
        x="112200"
        y="78600"
        textLength="550"
        font-size="680"
        lengthAdjust="spacingAndGlyphs"
        text-anchor="start"
        opacity="0"
      >
        6
      </text>
      <path
        d="M112570 78070
L112470 78070
L112420 78090
L112400 78110
L112350 78190
L112320 78280
L112320 78480
L112350 78530
L112370 78550
L112420 78580
L112520 78580
L112570 78550
L112590 78530
L112610 78480
L112610 78360
L112590 78310
L112570 78280
L112520 78260
L112420 78260
L112370 78280
L112350 78310
L112320 78360
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <path
        d="M3940 23620
L4720 23620
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <path
        d="M3940 43310
L4720 43310
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <path
        d="M3940 62990
L4720 62990
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <text
        x="4330"
        y="14030"
        textLength="500"
        font-size="680"
        lengthAdjust="spacingAndGlyphs"
        text-anchor="middle"
        opacity="0"
      >
        A
      </text>
      <path
        d="M4200 13860
L4450 13860
"
      />
      <path
        d="M4160 14010
L4330 13500
L4500 14010
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <text
        x="4330"
        y="33710"
        textLength="570"
        font-size="680"
        lengthAdjust="spacingAndGlyphs"
        text-anchor="middle"
        opacity="0"
      >
        B
      </text>
      <path
        d="M4360 33420
L4430 33440
L4460 33470
L4480 33520
L4480 33590
L4460 33640
L4430 33660
L4390 33690
L4190 33690
L4190 33180
L4360 33180
L4410 33200
L4430 33220
L4460 33270
L4460 33320
L4430 33370
L4410 33390
L4360 33420
L4190 33420
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <text
        x="4330"
        y="53400"
        textLength="570"
        font-size="680"
        lengthAdjust="spacingAndGlyphs"
        text-anchor="middle"
        opacity="0"
      >
        C
      </text>
      <path
        d="M4480 53330
L4460 53350
L4390 53380
L4340 53380
L4260 53350
L4220 53300
L4190 53250
L4170 53160
L4170 53080
L4190 52990
L4220 52940
L4260 52890
L4340 52870
L4390 52870
L4460 52890
L4480 52910
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <text
        x="4330"
        y="73080"
        textLength="570"
        font-size="680"
        lengthAdjust="spacingAndGlyphs"
        text-anchor="middle"
        opacity="0"
      >
        D
      </text>
      <path
        d="M4190 73060
L4190 72550
L4310 72550
L4390 72570
L4430 72620
L4460 72670
L4480 72760
L4480 72840
L4460 72930
L4430 72980
L4390 73030
L4310 73060
L4190 73060
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <path
        d="M112990 23620
L112210 23620
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <path
        d="M112990 43310
L112210 43310
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <path
        d="M112990 62990
L112210 62990
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <text
        x="112600"
        y="14030"
        textLength="500"
        font-size="680"
        lengthAdjust="spacingAndGlyphs"
        text-anchor="middle"
        opacity="0"
      >
        A
      </text>
      <path
        d="M112470 13860
L112720 13860
"
      />
      <path
        d="M112430 14010
L112600 13500
L112770 14010
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <text
        x="112600"
        y="33710"
        textLength="570"
        font-size="680"
        lengthAdjust="spacingAndGlyphs"
        text-anchor="middle"
        opacity="0"
      >
        B
      </text>
      <path
        d="M112630 33420
L112700 33440
L112730 33470
L112750 33520
L112750 33590
L112730 33640
L112700 33660
L112660 33690
L112460 33690
L112460 33180
L112630 33180
L112680 33200
L112700 33220
L112730 33270
L112730 33320
L112700 33370
L112680 33390
L112630 33420
L112460 33420
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <text
        x="112600"
        y="53400"
        textLength="570"
        font-size="680"
        lengthAdjust="spacingAndGlyphs"
        text-anchor="middle"
        opacity="0"
      >
        C
      </text>
      <path
        d="M112750 53330
L112730 53350
L112660 53380
L112610 53380
L112530 53350
L112490 53300
L112460 53250
L112440 53160
L112440 53080
L112460 52990
L112490 52940
L112530 52890
L112610 52870
L112660 52870
L112730 52890
L112750 52910
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <text
        x="112600"
        y="73080"
        textLength="570"
        font-size="680"
        lengthAdjust="spacingAndGlyphs"
        text-anchor="middle"
        opacity="0"
      >
        D
      </text>
      <path
        d="M112460 73060
L112460 72550
L112580 72550
L112660 72570
L112700 72620
L112730 72670
L112750 72760
L112750 72840
L112730 72930
L112700 72980
L112660 73030
L112580 73060
L112460 73060
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <text
        x="78740"
        y="76320"
        textLength="2760"
        font-size="780"
        lengthAdjust="spacingAndGlyphs"
        text-anchor="start"
        opacity="0"
      >
        Date:
      </text>
      <path
        d="M78910 76290
L78910 75700
L79050 75700
L79130 75730
L79190 75790
L79210 75840
L79240 75950
L79240 76040
L79210 76150
L79190 76210
L79130 76260
L79050 76290
L78910 76290
"
      />
      <path
        d="M79750 76290
L79750 75980
L79720 75930
L79660 75900
L79550 75900
L79500 75930
"
      />
      <path
        d="M79750 76260
L79690 76290
L79550 76290
L79500 76260
L79470 76210
L79470 76150
L79500 76100
L79550 76070
L79690 76070
L79750 76040
"
      />
      <path
        d="M79950 75900
L80170 75900
"
      />
      <path
        d="M80030 75700
L80030 76210
L80060 76260
L80110 76290
L80170 76290
"
      />
      <path
        d="M80590 76260
L80540 76290
L80420 76290
L80370 76260
L80340 76210
L80340 75980
L80370 75930
L80420 75900
L80540 75900
L80590 75930
L80620 75980
L80620 76040
L80340 76100
"
      />
      <path
        d="M80870 76240
L80900 76260
L80870 76290
L80840 76260
L80870 76240
L80870 76290
"
      />
      <path
        d="M80870 75930
L80900 75950
L80870 75980
L80840 75950
L80870 75930
L80870 75980
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <path
        d="M69690 76580
L112210 76580
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <text
        x="70080"
        y="77420"
        textLength="13520"
        font-size="780"
        lengthAdjust="spacingAndGlyphs"
        text-anchor="start"
        opacity="0"
      >
        KiCad E.D.A. kicad (5.1.9)-1
      </text>
      <path
        d="M70250 77390
L70250 76800
"
      />
      <path
        d="M70580 77390
L70330 77050
"
      />
      <path
        d="M70580 76800
L70250 77140
"
      />
      <path
        d="M70840 77390
L70840 77000
"
      />
      <path
        d="M70840 76800
L70810 76830
L70840 76860
L70860 76830
L70840 76800
L70840 76860
"
      />
      <path
        d="M71450 77340
L71430 77360
L71340 77390
L71290 77390
L71200 77360
L71140 77310
L71120 77250
L71090 77140
L71090 77050
L71120 76940
L71140 76890
L71200 76830
L71290 76800
L71340 76800
L71430 76830
L71450 76860
"
      />
      <path
        d="M71960 77390
L71960 77080
L71930 77030
L71880 77000
L71760 77000
L71710 77030
"
      />
      <path
        d="M71960 77360
L71900 77390
L71760 77390
L71710 77360
L71680 77310
L71680 77250
L71710 77200
L71760 77170
L71900 77170
L71960 77140
"
      />
      <path
        d="M72490 77390
L72490 76800
"
      />
      <path
        d="M72490 77360
L72440 77390
L72320 77390
L72270 77360
L72240 77340
L72210 77280
L72210 77110
L72240 77050
L72270 77030
L72320 77000
L72440 77000
L72490 77030
"
      />
      <path
        d="M73220 77080
L73420 77080
"
      />
      <path
        d="M73500 77390
L73220 77390
L73220 76800
L73500 76800
"
      />
      <path
        d="M73760 77340
L73790 77360
L73760 77390
L73730 77360
L73760 77340
L73760 77390
"
      />
      <path
        d="M74040 77390
L74040 76800
L74180 76800
L74260 76830
L74320 76890
L74350 76940
L74380 77050
L74380 77140
L74350 77250
L74320 77310
L74260 77360
L74180 77390
L74040 77390
"
      />
      <path
        d="M74630 77340
L74660 77360
L74630 77390
L74600 77360
L74630 77340
L74630 77390
"
      />
      <path
        d="M74880 77220
L75160 77220
"
      />
      <path
        d="M74830 77390
L75020 76800
L75220 77390
"
      />
      <path
        d="M75420 77340
L75440 77360
L75420 77390
L75390 77360
L75420 77340
L75420 77390
"
      />
      <path
        d="M76600 77390
L76600 76800
"
      />
      <path
        d="M76650 77170
L76820 77390
"
      />
      <path
        d="M76820 77000
L76600 77220
"
      />
      <path
        d="M77070 77390
L77070 77000
"
      />
      <path
        d="M77070 76800
L77040 76830
L77070 76860
L77100 76830
L77070 76800
L77070 76860
"
      />
      <path
        d="M77610 77360
L77550 77390
L77440 77390
L77380 77360
L77350 77340
L77330 77280
L77330 77110
L77350 77050
L77380 77030
L77440 77000
L77550 77000
L77610 77030
"
      />
      <path
        d="M78110 77390
L78110 77080
L78080 77030
L78030 77000
L77920 77000
L77860 77030
"
      />
      <path
        d="M78110 77360
L78060 77390
L77920 77390
L77860 77360
L77830 77310
L77830 77250
L77860 77200
L77920 77170
L78060 77170
L78110 77140
"
      />
      <path
        d="M78650 77390
L78650 76800
"
      />
      <path
        d="M78650 77360
L78590 77390
L78480 77390
L78420 77360
L78390 77340
L78370 77280
L78370 77110
L78390 77050
L78420 77030
L78480 77000
L78590 77000
L78650 77030
"
      />
      <path
        d="M79550 77620
L79520 77590
L79460 77500
L79430 77450
L79400 77360
L79380 77220
L79380 77110
L79400 76970
L79430 76890
L79460 76830
L79520 76750
L79550 76720
"
      />
      <path
        d="M80050 76800
L79770 76800
L79740 77080
L79770 77050
L79830 77030
L79970 77030
L80020 77050
L80050 77080
L80080 77140
L80080 77280
L80050 77340
L80020 77360
L79970 77390
L79830 77390
L79770 77360
L79740 77340
"
      />
      <path
        d="M80330 77340
L80360 77360
L80330 77390
L80300 77360
L80330 77340
L80330 77390
"
      />
      <path
        d="M80920 77390
L80580 77390
"
      />
      <path
        d="M80750 77390
L80750 76800
L80700 76890
L80640 76940
L80580 76970
"
      />
      <path
        d="M81170 77340
L81200 77360
L81170 77390
L81150 77360
L81170 77340
L81170 77390
"
      />
      <path
        d="M81480 77390
L81600 77390
L81650 77360
L81680 77340
L81740 77250
L81760 77140
L81760 76910
L81740 76860
L81710 76830
L81650 76800
L81540 76800
L81480 76830
L81460 76860
L81430 76910
L81430 77050
L81460 77110
L81480 77140
L81540 77170
L81650 77170
L81710 77140
L81740 77110
L81760 77050
"
      />
      <path
        d="M81960 77620
L81990 77590
L82050 77500
L82070 77450
L82100 77360
L82130 77220
L82130 77110
L82100 76970
L82070 76890
L82050 76830
L81990 76750
L81960 76720
"
      />
      <path
        d="M82410 77170
L82860 77170
"
      />
      <path
        d="M83450 77390
L83110 77390
"
      />
      <path
        d="M83280 77390
L83280 76800
L83230 76890
L83170 76940
L83110 76970
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <path
        d="M69690 75400
L112210 75400
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:120; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <text
        x="103540"
        y="76320"
        textLength="2340"
        font-size="780"
        lengthAdjust="spacingAndGlyphs"
        text-anchor="start"
        opacity="0"
      >
        Rev:
      </text>
      <path
        d="M104090 76290
L103890 76010
"
      />
      <path
        d="M103750 76290
L103750 75700
L103980 75700
L104030 75730
L104060 75760
L104090 75810
L104090 75900
L104060 75950
L104030 75980
L103980 76010
L103750 76010
"
      />
      <path
        d="M104570 76260
L104510 76290
L104400 76290
L104340 76260
L104320 76210
L104320 75980
L104340 75930
L104400 75900
L104510 75900
L104570 75930
L104600 75980
L104600 76040
L104320 76100
"
      />
      <path
        d="M104790 75900
L104930 76290
L105070 75900
"
      />
      <path
        d="M105300 76240
L105330 76260
L105300 76290
L105270 76260
L105300 76240
L105300 76290
"
      />
      <path
        d="M105300 75930
L105330 75950
L105300 75980
L105270 75950
L105300 75930
L105300 75980
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <text
        x="70080"
        y="76320"
        textLength="3780"
        font-size="780"
        lengthAdjust="spacingAndGlyphs"
        text-anchor="start"
        opacity="0"
      >
        Size: A4
      </text>
      <path
        d="M70220 76260
L70300 76290
L70440 76290
L70500 76260
L70530 76240
L70550 76180
L70550 76120
L70530 76070
L70500 76040
L70440 76010
L70330 75980
L70270 75950
L70250 75930
L70220 75870
L70220 75810
L70250 75760
L70270 75730
L70330 75700
L70470 75700
L70550 75730
"
      />
      <path
        d="M70810 76290
L70810 75900
"
      />
      <path
        d="M70810 75700
L70780 75730
L70810 75760
L70840 75730
L70810 75700
L70810 75760
"
      />
      <path
        d="M71030 75900
L71340 75900
L71030 76290
L71340 76290
"
      />
      <path
        d="M71790 76260
L71730 76290
L71620 76290
L71570 76260
L71540 76210
L71540 75980
L71570 75930
L71620 75900
L71730 75900
L71790 75930
L71820 75980
L71820 76040
L71540 76100
"
      />
      <path
        d="M72070 76240
L72100 76260
L72070 76290
L72040 76260
L72070 76240
L72070 76290
"
      />
      <path
        d="M72070 75930
L72100 75950
L72070 75980
L72040 75950
L72070 75930
L72070 75980
"
      />
      <path
        d="M72770 76120
L73060 76120
"
      />
      <path
        d="M72720 76290
L72910 75700
L73110 76290
"
      />
      <path
        d="M73560 75900
L73560 76290
"
      />
      <path
        d="M73420 75670
L73280 76100
L73650 76100
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <text
        x="103540"
        y="77420"
        textLength="4470"
        font-size="780"
        lengthAdjust="spacingAndGlyphs"
        text-anchor="start"
        opacity="0"
      >
        Id: 13/13
      </text>
      <path
        d="M103710 77390
L103710 76800
"
      />
      <path
        d="M104240 77390
L104240 76800
"
      />
      <path
        d="M104240 77360
L104180 77390
L104070 77390
L104010 77360
L103990 77340
L103960 77280
L103960 77110
L103990 77050
L104010 77030
L104070 77000
L104180 77000
L104240 77030
"
      />
      <path
        d="M104520 77340
L104550 77360
L104520 77390
L104490 77360
L104520 77340
L104520 77390
"
      />
      <path
        d="M104520 77030
L104550 77050
L104520 77080
L104490 77050
L104520 77030
L104520 77080
"
      />
      <path
        d="M105560 77390
L105220 77390
"
      />
      <path
        d="M105390 77390
L105390 76800
L105340 76890
L105280 76940
L105220 76970
"
      />
      <path
        d="M105760 76800
L106120 76800
L105930 77030
L106010 77030
L106070 77050
L106090 77080
L106120 77140
L106120 77280
L106090 77340
L106070 77360
L106010 77390
L105840 77390
L105780 77360
L105760 77340
"
      />
      <path
        d="M106800 76770
L106290 77530
"
      />
      <path
        d="M107300 77390
L106960 77390
"
      />
      <path
        d="M107130 77390
L107130 76800
L107080 76890
L107020 76940
L106960 76970
"
      />
      <path
        d="M107500 76800
L107860 76800
L107670 77030
L107750 77030
L107810 77050
L107840 77080
L107860 77140
L107860 77280
L107840 77340
L107810 77360
L107750 77390
L107580 77390
L107530 77360
L107500 77340
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <path
        d="M69690 73820
L112210 73820
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:160; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <text
        x="70080"
        y="74920"
        textLength="3710"
        font-size="1050"
        lengthAdjust="spacingAndGlyphs"
        text-anchor="start"
        opacity="0"
      >
        Title:
      </text>
      <path
        d="M70360 74090
L70810 74090
"
      />
      <path
        d="M70480 74880
L70580 74090
"
      />
      <path
        d="M70970 74880
L71040 74360
"
      />
      <path
        d="M71070 74090
L71030 74130
L71060 74170
L71110 74130
L71070 74090
L71060 74170
"
      />
      <path
        d="M71300 74360
L71600 74360
"
      />
      <path
        d="M71450 74090
L71360 74770
L71390 74840
L71460 74880
L71540 74880
"
      />
      <path
        d="M71910 74880
L71840 74840
L71820 74770
L71900 74090
"
      />
      <path
        d="M72520 74840
L72440 74880
L72290 74880
L72220 74840
L72190 74770
L72230 74470
L72280 74390
L72360 74360
L72510 74360
L72580 74390
L72610 74470
L72600 74540
L72210 74620
"
      />
      <path
        d="M72900 74810
L72930 74840
L72890 74880
L72860 74840
L72900 74810
L72890 74880
"
      />
      <path
        d="M72950 74390
L72990 74430
L72940 74470
L72910 74430
L72950 74390
L72940 74470
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <text
        x="70080"
        y="73400"
        textLength="10240"
        font-size="780"
        lengthAdjust="spacingAndGlyphs"
        text-anchor="start"
        opacity="0"
      >
        File: file614A62B2.sch
      </text>
      <path
        d="M70440 73060
L70250 73060
"
      />
      <path
        d="M70250 73370
L70250 72780
L70530 72780
"
      />
      <path
        d="M70750 73370
L70750 72980
"
      />
      <path
        d="M70750 72780
L70720 72810
L70750 72840
L70780 72810
L70750 72780
L70750 72840
"
      />
      <path
        d="M71120 73370
L71060 73340
L71030 73290
L71030 72780
"
      />
      <path
        d="M71570 73340
L71510 73370
L71400 73370
L71340 73340
L71310 73290
L71310 73060
L71340 73010
L71400 72980
L71510 72980
L71570 73010
L71590 73060
L71590 73120
L71310 73180
"
      />
      <path
        d="M71850 73320
L71880 73340
L71850 73370
L71820 73340
L71850 73320
L71850 73370
"
      />
      <path
        d="M71850 73010
L71880 73030
L71850 73060
L71820 73030
L71850 73010
L71850 73060
"
      />
      <path
        d="M72490 72980
L72720 72980
"
      />
      <path
        d="M72580 73370
L72580 72870
L72610 72810
L72660 72780
L72720 72780
"
      />
      <path
        d="M72910 73370
L72910 72980
"
      />
      <path
        d="M72910 72780
L72890 72810
L72910 72840
L72940 72810
L72910 72780
L72910 72840
"
      />
      <path
        d="M73280 73370
L73220 73340
L73200 73290
L73200 72780
"
      />
      <path
        d="M73730 73340
L73670 73370
L73560 73370
L73500 73340
L73480 73290
L73480 73060
L73500 73010
L73560 72980
L73670 72980
L73730 73010
L73760 73060
L73760 73120
L73480 73180
"
      />
      <path
        d="M74260 72780
L74150 72780
L74090 72810
L74070 72840
L74010 72920
L73980 73030
L73980 73260
L74010 73320
L74040 73340
L74090 73370
L74210 73370
L74260 73340
L74290 73320
L74320 73260
L74320 73120
L74290 73060
L74260 73030
L74210 73010
L74090 73010
L74040 73030
L74010 73060
L73980 73120
"
      />
      <path
        d="M74880 73370
L74540 73370
"
      />
      <path
        d="M74710 73370
L74710 72780
L74660 72870
L74600 72920
L74540 72950
"
      />
      <path
        d="M75390 72980
L75390 73370
"
      />
      <path
        d="M75250 72750
L75110 73180
L75470 73180
"
      />
      <path
        d="M75670 73200
L75950 73200
"
      />
      <path
        d="M75610 73370
L75810 72780
L76010 73370
"
      />
      <path
        d="M76450 72780
L76340 72780
L76290 72810
L76260 72840
L76200 72920
L76170 73030
L76170 73260
L76200 73320
L76230 73340
L76290 73370
L76400 73370
L76450 73340
L76480 73320
L76510 73260
L76510 73120
L76480 73060
L76450 73030
L76400 73010
L76290 73010
L76230 73030
L76200 73060
L76170 73120
"
      />
      <path
        d="M76740 72840
L76760 72810
L76820 72780
L76960 72780
L77020 72810
L77040 72840
L77070 72890
L77070 72950
L77040 73030
L76710 73370
L77070 73370
"
      />
      <path
        d="M77520 73060
L77610 73090
L77630 73120
L77660 73180
L77660 73260
L77630 73320
L77610 73340
L77550 73370
L77330 73370
L77330 72780
L77520 72780
L77580 72810
L77610 72840
L77630 72890
L77630 72950
L77610 73010
L77580 73030
L77520 73060
L77330 73060
"
      />
      <path
        d="M77890 72840
L77920 72810
L77970 72780
L78110 72780
L78170 72810
L78200 72840
L78220 72890
L78220 72950
L78200 73030
L77860 73370
L78220 73370
"
      />
      <path
        d="M78480 73320
L78510 73340
L78480 73370
L78450 73340
L78480 73320
L78480 73370
"
      />
      <path
        d="M78730 73340
L78790 73370
L78900 73370
L78960 73340
L78980 73290
L78980 73260
L78960 73200
L78900 73180
L78810 73180
L78760 73150
L78730 73090
L78730 73060
L78760 73010
L78810 72980
L78900 72980
L78960 73010
"
      />
      <path
        d="M79490 73340
L79430 73370
L79320 73370
L79260 73340
L79240 73320
L79210 73260
L79210 73090
L79240 73030
L79260 73010
L79320 72980
L79430 72980
L79490 73010
"
      />
      <path
        d="M79740 73370
L79740 72780
"
      />
      <path
        d="M79990 73370
L79990 73060
L79970 73010
L79910 72980
L79830 72980
L79770 73010
L79740 73030
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <path
        d="M69690 71460
L112210 71460
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <text
        x="70080"
        y="72340"
        textLength="11380"
        font-size="780"
        lengthAdjust="spacingAndGlyphs"
        text-anchor="start"
        opacity="0"
      >
        Sheet: /Sheet614A62B2/
      </text>
      <path
        d="M70220 72280
L70300 72310
L70440 72310
L70500 72280
L70530 72260
L70550 72200
L70550 72140
L70530 72090
L70500 72060
L70440 72030
L70330 72000
L70270 71970
L70250 71950
L70220 71890
L70220 71830
L70250 71780
L70270 71750
L70330 71720
L70470 71720
L70550 71750
"
      />
      <path
        d="M70810 72310
L70810 71720
"
      />
      <path
        d="M71060 72310
L71060 72000
L71030 71950
L70980 71920
L70890 71920
L70840 71950
L70810 71970
"
      />
      <path
        d="M71570 72280
L71510 72310
L71400 72310
L71340 72280
L71310 72230
L71310 72000
L71340 71950
L71400 71920
L71510 71920
L71570 71950
L71590 72000
L71590 72060
L71310 72120
"
      />
      <path
        d="M72070 72280
L72020 72310
L71900 72310
L71850 72280
L71820 72230
L71820 72000
L71850 71950
L71900 71920
L72020 71920
L72070 71950
L72100 72000
L72100 72060
L71820 72120
"
      />
      <path
        d="M72270 71920
L72490 71920
"
      />
      <path
        d="M72350 71720
L72350 72230
L72380 72280
L72440 72310
L72490 72310
"
      />
      <path
        d="M72690 72260
L72720 72280
L72690 72310
L72660 72280
L72690 72260
L72690 72310
"
      />
      <path
        d="M72690 71950
L72720 71970
L72690 72000
L72660 71970
L72690 71950
L72690 72000
"
      />
      <path
        d="M73840 71690
L73340 72450
"
      />
      <path
        d="M74010 72280
L74090 72310
L74240 72310
L74290 72280
L74320 72260
L74350 72200
L74350 72140
L74320 72090
L74290 72060
L74240 72030
L74120 72000
L74070 71970
L74040 71950
L74010 71890
L74010 71830
L74040 71780
L74070 71750
L74120 71720
L74260 71720
L74350 71750
"
      />
      <path
        d="M74600 72310
L74600 71720
"
      />
      <path
        d="M74850 72310
L74850 72000
L74830 71950
L74770 71920
L74680 71920
L74630 71950
L74600 71970
"
      />
      <path
        d="M75360 72280
L75300 72310
L75190 72310
L75130 72280
L75110 72230
L75110 72000
L75130 71950
L75190 71920
L75300 71920
L75360 71950
L75390 72000
L75390 72060
L75110 72120
"
      />
      <path
        d="M75860 72280
L75810 72310
L75700 72310
L75640 72280
L75610 72230
L75610 72000
L75640 71950
L75700 71920
L75810 71920
L75860 71950
L75890 72000
L75890 72060
L75610 72120
"
      />
      <path
        d="M76060 71920
L76290 71920
"
      />
      <path
        d="M76150 71720
L76150 72230
L76170 72280
L76230 72310
L76290 72310
"
      />
      <path
        d="M76740 71720
L76620 71720
L76570 71750
L76540 71780
L76480 71860
L76450 71970
L76450 72200
L76480 72260
L76510 72280
L76570 72310
L76680 72310
L76740 72280
L76760 72260
L76790 72200
L76790 72060
L76760 72000
L76740 71970
L76680 71950
L76570 71950
L76510 71970
L76480 72000
L76450 72060
"
      />
      <path
        d="M77350 72310
L77020 72310
"
      />
      <path
        d="M77190 72310
L77190 71720
L77130 71810
L77070 71860
L77020 71890
"
      />
      <path
        d="M77860 71920
L77860 72310
"
      />
      <path
        d="M77720 71690
L77580 72120
L77940 72120
"
      />
      <path
        d="M78140 72140
L78420 72140
"
      />
      <path
        d="M78080 72310
L78280 71720
L78480 72310
"
      />
      <path
        d="M78930 71720
L78810 71720
L78760 71750
L78730 71780
L78670 71860
L78650 71970
L78650 72200
L78670 72260
L78700 72280
L78760 72310
L78870 72310
L78930 72280
L78960 72260
L78980 72200
L78980 72060
L78960 72000
L78930 71970
L78870 71950
L78760 71950
L78700 71970
L78670 72000
L78650 72060
"
      />
      <path
        d="M79210 71780
L79240 71750
L79290 71720
L79430 71720
L79490 71750
L79520 71780
L79550 71830
L79550 71890
L79520 71970
L79180 72310
L79550 72310
"
      />
      <path
        d="M79990 72000
L80080 72030
L80110 72060
L80140 72120
L80140 72200
L80110 72260
L80080 72280
L80020 72310
L79800 72310
L79800 71720
L79990 71720
L80050 71750
L80080 71780
L80110 71830
L80110 71890
L80080 71950
L80050 71970
L79990 72000
L79800 72000
"
      />
      <path
        d="M80360 71780
L80390 71750
L80440 71720
L80580 71720
L80640 71750
L80670 71780
L80700 71830
L80700 71890
L80670 71970
L80330 72310
L80700 72310
"
      />
      <path
        d="M81370 71690
L80870 72450
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <path
        d="M77560 75400
L77560 76580
"
      />
    </g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:100; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    ></g>
    <g
      style="fill:#840000; fill-opacity:0.0; 
stroke:#840000; stroke-width:60; stroke-opacity:1; 
stroke-linecap:round; stroke-linejoin:round;"
    >
      <path
        d="M102760 75400
L102760 77960
"
      />
    </g>
  </g>
</template>
<script>
export default {
  name: "TpcbTemplate"
}
</script>
